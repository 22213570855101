import React from "react";

import ContactForm from "../../../../ContactForm";

const BaustellenversorgungContact = ({ name = "baustellenversorgung" }) => {
    return (
        <ContactForm
            urlEnding="baustellenversorgung"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
        />
    );
};

export default BaustellenversorgungContact;
